<template>
  <b-overlay
      :show="show"
      rounded="sm"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col md="10">
          <b-row>
            <b-col md="2">
              <b-form-group label="Eşleşme Statüsü">
                <v-select
                    v-model="_search.status"
                    label="status"
                    multiple
                    :options="_matchStatus"
                    :reduce="statu => statu.id"
                    class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="TY SKU">
                <b-form-input type="text" size="sm" v-model="_search.tySku"/>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="TY ID">
                <b-form-input type="number" size="sm" v-model="_search.tyId"/>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Limit">
                <v-select
                    v-model="_search.limit"
                    :options="[1,5,10,20,50,100,250,500]"
                    class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Sayfa">
                <b-form-input type="number" size="sm" v-model="_search.page"/>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
        <b-col md="2">
          <b-form-group label="Yönetim">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getAll">Getir</b-button>
              <b-button variant="danger" @click="clearSearch">Temizle</b-button>
            </b-button-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-row v-if="_pagination">
      Sayfa: {{ _pagination.current_page }} / {{ _pagination.last_page }}
      Ürün: {{ _pagination.from }} ile {{ _pagination.to }} arası {{ _pagination.per_page }} / {{
        _pagination.total
      }}
    </b-row>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "Search",
  components: {vSelect},
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('tyProduct', ["_products", "_search", "_pagination"]),
    ...mapGetters('matchStatus', ['_matchStatus']),
  },
  methods: {
    getAll() {
      this.show = true
      this.$store.dispatch('tyProduct/getProducts')
          .then(res => {
            this.show = false;
          })
          .catch(() => {
            this.show = false;
          })
    },
    clearSearch() {

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
