<template>
  <b-col md="12">
    <b-overlay
        :show="show"
        rounded="sm"
    >
      <b-row>
        <b-col md="1" class="border-right">
          {{ tyProduct.id }}
        </b-col>

        <b-col md="2">
          <ProductImage :images="product.images" :prefix="'https://api.kitapfirsatlari.com/'"/>
        </b-col>
        <b-col md="2">
          <ProductImage :images="tyProduct.images"/>
        </b-col>

        <b-col md="3">
          <feather-icon icon="ExternalLinkIcon"/>
          <b>{{ product.name }}</b><br>
          {{ product.barcode }}<br>

          <b-row>
            <b-list-group size="sm">
              <b-list-group-item v-for="supplierProduct in product.supplier_products" :key="supplierProduct.id">
                <a :href="supplierProduct.url" target="_blank">
                  <feather-icon icon="ExternalLinkIcon"/>
                </a>
                B:{{ supplierProduct.barcode }} A:{{ supplierProduct.price }}₺ P:{{ supplierProduct.market_price }}₺
                S:{{ supplierProduct.stock }}Ad.
              </b-list-group-item>

            </b-list-group>
          </b-row>
          <hr>
          <b-row>
            <b-list-group size="sm" class="m-0 t-0">
              <b-list-group-item v-for="category in product.categories" :key="category.id">
                {{ category.name }}
              </b-list-group-item>
            </b-list-group>
          </b-row>
          <hr>
          <b-row>
            <b-list-group size="sm">
              <b-list-group-item v-for="publisher in product.publishers" :key="publisher.id">
                {{ publisher.publisher_name }}
              </b-list-group-item>
            </b-list-group>
          </b-row>

        </b-col>


        <b-col md="3">
          <a :href="tyProduct.url" target="_blank">
            <feather-icon icon="ExternalLinkIcon"/>
          </a> <b>{{ tyProduct.product_name }}</b> <br>
          Barkod: <p :class="tyProduct.merchant_sku == product.barcode ? 'text-success':'text-danger'">{{
            tyProduct.merchant_sku
          }} </p>
          Marka: {{ detail.brandName }} <br>
          Kat.: {{ detail.categoryName }} <br>
        </b-col>
        <b-col md="1">
          <MatchStatus :status-id="product.pivot.status" @changeStatus="changeStatus($event)"/>
        </b-col>
      </b-row>
    </b-overlay>
  </b-col>
</template>

<script>
import ProductImage from "@/views/trendyol/product/ProductImage";
import MatchStatus from "@/components/matchStatus";

export default {
  name: "TyProduct",
  components: {ProductImage, MatchStatus},
  data: () => ({show: false}),
  props: {
    tyProduct: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
  },
  computed: {
    detail() {
      if (this.tyProduct.detail) {
        return JSON.parse(this.tyProduct.detail.detail);
      }
      return {}
    }
  },
  methods: {
    changeStatus(statusId) {
      this.show = true;
      this.$store.dispatch('tyProduct/setProductPivotStatus', {
        ty_product_id: this.tyProduct.id,
        product_id: this.product.id,
        status: statusId
      })
          .then(res => {
            this.show = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
