<template>
  <div>
    <b-button @click="ImageModal">
      <b-carousel
          id="carousel-1"
          ref="myCarousel"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="100"
          img-height="150"
      >
        <b-carousel-slide v-for="image in images" :img-src="prefix + image.url"/>
      </b-carousel>
    </b-button>
    <!-- programmatically  -->
    <div class="d-flex align-items-center justify-content-center mt-1" v-if="images.length>1">
      <b-button-group size="sm">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="prev"
        >
          <feather-icon icon="SkipBackIcon"/>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="start"
        >
          <feather-icon :icon="status ? 'PlayCircleIcon' : 'StopCircleIcon'"/>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="next"
        >
          <feather-icon icon="SkipForwardIcon"/>
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

export default {
  name: "ProductImage",
  data: () => ({
    status: false,
  }),
  directives: {
    Ripple,
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      default: ""
    }
  },
  methods: {
    ImageModal() {
      alert('Resim Büyütme Yapılacak')
    },
    prev() {
      if (!this.status) {
        this.$refs.myCarousel.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.myCarousel.next()
      }
    },
    start() {
      this.status = !this.status
      if (this.status) {
        this.$refs.myCarousel.pause()
      } else {
        this.$refs.myCarousel.start()
      }
    },
  },
}
</script>

<style scoped>

</style>
