<template>
  <div>
    Eşleşme:
    <span v-if="[0].includes(statusId)">
      <b-icon icon="stop-circle-fill" variant="warning"/> &nbsp;
        <b-button-group>
      <b-button
          size="sm"
          variant="success"
          class="pt-0 pb-0"
          @click="changeStatus(2)"
      >
          <b-icon icon="check-circle-fill"/>
          Onayla
        </b-button>

      <b-button
          size="sm"
          variant="danger"
          class="pt-0 pb-0"
          @click="changeStatus(4)"
      >
          <b-icon icon="x-circle-fill"/>
          Reddet
        </b-button>
        </b-button-group>
    </span>
    <span v-else-if="[1,2].includes(statusId)">
      <b-icon icon="check-circle-fill" variant="success"/> &nbsp;
        <b-button
            size="sm"
            variant="light"
            class="pt-0 pb-0"
            @click="changeStatus(4)"
        >
          <b-icon icon="x-circle-fill"/>
          Pasif Et
        </b-button>
    </span>
    <span v-else>
       <b-icon icon="x-circle-fill" variant="danger"/> &nbsp;
      <b-button
          size="sm"
          variant="light"
          class="pt-0 pb-0"
          @click="changeStatus(2)"
      >
          <b-icon icon="check-circle-fill"/>
          Aktif Et
        </b-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "matchStatus",
  props: {
    statusId: {
      type: Number,
      required: true
    }
  },
  methods: {
    changeStatus(status) {
      if (confirm("Ürün statüsünü değiştirmek istediğinizden emin misiniz?") == true) {
        this.$emit('changeStatus', status)
      }

    }
  }
}
</script>

<style scoped>

</style>
